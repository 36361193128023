import React, { useState } from 'react'
import { Icons, SideFlyoutContainer } from 'msp-components'
import { JasminSearchBox } from '../JasminSearchBox'
import { HeaderMenuFlyoutProps } from './HeaderMenuFlyout.types'
import HeaderMenu from '../HeaderMenu/HeaderMenu'
import { HeaderMenuBottom } from '../HeaderMenuBottom'
import { twMerge } from 'tailwind-merge'

const title = (
  <div className='flex justify-center fill-white md:justify-start md:pl-3'>
    <Icons iconName='JasminLogo' height='40px' width='150px' />
  </div>
)

const HeaderMenuFlyout = ({
  className = '',
  isOpen,
  onOpen,
  onClose,
  areas
}: HeaderMenuFlyoutProps) => {
  const [flyoutContainerWidth, setFlyoutContainerWidth] = useState(
    'w-screen md:w-[478px]'
  )

  return (
    <SideFlyoutContainer
      position={'left'}
      width={flyoutContainerWidth}
      titleContent={title}
      fixedTitle={true}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      isDarkMode={true}
    >
      <div
        className={twMerge('hide-scrollbar h-full overflow-auto', className)}
        data-cy='header-menu-flyout'
      >
        <JasminSearchBox
          onResultClick={onClose}
          className='ml-[70px] mr-[30px] mt-20 block stroke-white pt-1 text-white md:hidden'
          position='absolute'
          iconColor='white'
          isDarkMode={true}
          showDeleteButton={true}
          classNameResults={'bg-darkGray shadow-black shadow-xl'}
        />
        <div data-testid={'header-menu-flyout'}>
          <div className='menu-wrapper'>
            <HeaderMenu
              content={areas?.get('menu')}
              closeFlyout={onClose}
              changeFlyoutWidth={setFlyoutContainerWidth}
            />
          </div>
          <HeaderMenuBottom className='' onSuccess={onClose} />
        </div>
      </div>
    </SideFlyoutContainer>
  )
}

export default HeaderMenuFlyout
