import React from 'react'
import { SlideOver, Icons } from 'msp-components'
import { IconButton } from '@/components/molecules/IconButton'
import { JasminSearchBox } from '@/components/organisms/JasminSearchBox'
import * as T from './JasminDesktopSearch.types'

const ICONS_HEIGHT = '20px'

/**
 * Header top bar that includes the search box
 */
const JasminDesktopSearch = ({
  showSearch,
  setShowSearch,
  isDarkMode = false
}: T.JasminDesktopSearchProps) => {
  const searchFormRef = React.useRef(null)
  // setTimeout is required to wait for the animation to be finished before removing the element
  const onCloseFlyout = () => setTimeout(() => setShowSearch(false), 100)

  return (
    <SlideOver
      position='top'
      backdropOpacity='bg-opacity-25'
      isOpen={showSearch}
      onOpen={() => searchFormRef?.current[0]?.focus()}
      onClose={onCloseFlyout}
    >
      <div
        className='font-Roboto relative max-h-[100vh] min-h-[240px] w-full bg-black fill-white'
        data-cy={'search-flyout-desktop'}
      >
        <div className='relative flex h-full items-start justify-between p-10'>
          <div className='relative top-6 transform-gpu'>
            <Icons iconName='JasminLogo' width='150px' height='100px' />
          </div>
          <JasminSearchBox
            className='-ml-20 mt-12 w-[600px] max-w-[50%] stroke-white text-white'
            formRef={searchFormRef}
            onResultClick={() => setShowSearch(false)}
            position='absolute'
            onClose={() => setShowSearch(false)}
            isDarkMode={isDarkMode}
            classNameResults={'z-[1100] bg-darkGray'}
          />
          <div className='w-12'>
            <IconButton
              iconName='Close'
              onClick={onCloseFlyout}
              height={ICONS_HEIGHT}
            />
          </div>
        </div>
      </div>
    </SlideOver>
  )
}

export default JasminDesktopSearch
