import React from 'react'
import { useIntl } from 'react-intl'
import { useCustomer, useNavigation, useCookie } from 'msp-integrations'
import { Icons, CustomLinkComponent } from 'msp-components'
import { UserAccountFlyoutSummaryProps } from './UserAccountFlyoutSummary.types'

const UserAccountFlyoutSummary = ({
  className,
  onLogoutSuccessful,
  isDarkMode
}: UserAccountFlyoutSummaryProps) => {
  const { formatMessage, locale, defaultLocale } = useIntl()
  const navigate = useNavigation()
  const customer = useCustomer()
  const { eventTracking } = useCookie()

  const onLogoutClick = async () => {
    await customer.logout()
    onLogoutSuccessful && onLogoutSuccessful()
    eventTracking('logout', { loggedIn: 0 })
  }

  const navigateTo = (link: string) => {
    navigate(link)
    onLogoutSuccessful && onLogoutSuccessful()
  }

  const getButton = (
    href: string,
    translation: string,
    isDarkMode?: boolean
  ) => (
    <div>
      <button onClick={() => navigateTo(href)}>
        <CustomLinkComponent
          className='tracking-3'
          href={href}
          colorScheme={isDarkMode && 'secondary'}
        >
          {translation}
        </CustomLinkComponent>
      </button>
    </div>
  )

  return (
    <div
      className={`font-Roboto text-10 flex flex-col ${className}`}
      data-testid={'user-account-summary'}
      data-cy={'loggedIn-summary'}
    >
      <div
        className={`font-Roboto text-14 tracking-0 mt-4 space-y-2 font-thin ${
          isDarkMode ? 'text-lightGrayVariant' : 'text-menuGray'
        }`}
      >
        <div>
          {`${formatMessage({
            defaultMessage: 'Customer Number',
            id: 'msp.account_detail.customer.number'
          })}: ${customer.customerNo}`}
        </div>
        <div>
          {`${formatMessage({
            defaultMessage: 'Credit',
            id: 'msp.account_detail.customer.credit'
          })}: ${(customer.c_customerCredit || 0).toLocaleString(
            locale || defaultLocale,
            {
              minimumFractionDigits: 2
            }
          )} \u20ac`}
        </div>
      </div>

      <div className={'mt-6 space-y-4'}>
        {getButton(
          '/account',
          formatMessage({
            defaultMessage: 'Account Details',
            id: 'msp.account_detail.account_details'
          }),
          isDarkMode
        )}

        {getButton(
          '/account/orders',
          formatMessage({
            defaultMessage: 'Order History',
            id: 'msp.account_detail.order_history'
          }),
          isDarkMode
        )}
      </div>

      <div className={'mt-6'}>
        <button onClick={onLogoutClick} data-cy={'flyout-logout-button'}>
          <div className={'flex space-x-2 align-middle'}>
            <Icons iconName='PowerButton' size='lg' />
            <CustomLinkComponent
              className='tracking-3'
              colorScheme={isDarkMode && 'secondary'}
            >
              {formatMessage({
                defaultMessage: 'Logout',
                id: 'msp.account_detail.button.log_out'
              })}
            </CustomLinkComponent>
          </div>
        </button>
      </div>
    </div>
  )
}

export default UserAccountFlyoutSummary
